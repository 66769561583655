import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import PersonsData from "./pages/SearchandFilters/Persons";
import { MunicipalityProvider } from "./components/Context/MuncipiltyContext";
import React, { useEffect } from "react";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import NavBar from "./components/navbar";
import UserContext from "./components/Context/userContext";
import Users from "./pages/userManagment";
import VoterProfile from "./components/voterprofile";
import LookerReport from "./components/lookerdashboard";
import Login from "./pages/LoginPage/LoginPage";
import OTPForm from "./components/OneTimePassword";
import ContentWrapper from "./components/content-wrapper";
import PrivateRoute from "./components/Routes/privateRoute";
import CompaniesData from "./pages/CompanySearch";
import CompanyProfile from "./components/companyProfile";

function App() {
  const [userRole, setUserRole] = React.useState(null);
  useEffect(() => {
    const token = Cookies.get("ElectionToken");
    if (token) {
      const { role } = jwtDecode(token);
      setUserRole(role);
    }
  }, []);

  return (
    <div className="App">
      <ToastContainer />
      <UserContext.Provider value={{ userRole, setUserRole }}>
        <MunicipalityProvider>
          <BrowserRouter>
            <ContentWrapper>
              <NavBar />
              <Switch>
                <Route exact path="/login" component={Login} />
                <PrivateRoute exact path="/" component={PersonsData} />
                <PrivateRoute exact path="/users" component={Users} />
                <PrivateRoute
                  path="/personaldetails/:id"
                  component={VoterProfile}
                />
                <PrivateRoute
                  path="/companydetails/:id"
                  component={CompanyProfile}
                />
                <Route exact path="/otpCheck" component={OTPForm} />
                <PrivateRoute
                  exact
                  path="/dashboard"
                  component={LookerReport}
                />
                <PrivateRoute
                  exact
                  path="/companies"
                  component={CompaniesData}
                />
              </Switch>
            </ContentWrapper>
          </BrowserRouter>
        </MunicipalityProvider>
      </UserContext.Provider>
    </div>
  );
}

export default App;
