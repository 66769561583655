import axios from "axios";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { BASE_URL } from "../../config";

// function to get the user data
export const FetchingUserData = async (edituser) => {
  const url = `${BASE_URL}/api/updateuser/${edituser}`;

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${Cookies.get("ElectionToken")}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message || "An error occurred");
  }
};
// function to update the user data
export const updateUser = async (edituser, userData) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/api/updateuser/${edituser}`,
      userData,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("ElectionToken")}`,
        },
      }
    );

    if (response.data.token) {
      Cookies.set("ElectionToken", response.data.token);
    }

    if (response.status === 200) {
      return true;
    }
  } catch (error) {
    if (
      error.response &&
      error.response.data.message === "Token is invalid or expired"
    ) {
      Cookies.remove("ElectionToken");
      throw new Error("Token is invalid or expired");
    } else {
      throw new Error(error.response.data.message || "An error occurred");
    }
  }
};
export const addUser = async (info) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/getusers`,
      info,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("ElectionToken")}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    if (
      error.response &&
      error.response.data.message === "Token is invalid or expired"
    ) {
      Cookies.remove("ElectionToken");
      throw new Error("Token is invalid or expired");
    } else {
      throw new Error(error.response.data.message || "An error occurred");
    }
  }
};

////////////////////////////////////////////////////////////////////////////////////////////////
export const gettingUsers = async () => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/getusers`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("ElectionToken")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (
      error.response &&
      error.response.data.message === "Token is invalid or expired"
    ) {
      Cookies.remove("ElectionToken");
      throw new Error("Token is invalid or expired");
    } else {
      throw new Error(error.response.data.message || "An error occurred");
    }
  }
};

////////////////////////////////////////////////////////////////////////////////////////////////

export const deleteUser = async (userId) => {
  const token = Cookies.get("ElectionToken");
  const decodedToken = jwtDecode(token);

  if (decodedToken.id === userId) {
    throw new Error("You cannot delete your own account!");
  }

  const response = await axios.delete(
    `${BASE_URL}/api/updateuser/${userId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data.message;
};
